import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function invoiceApplyItemList(config) {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'no', label: 'No.'},
    { key: 'orderNo', label: '单据编号' },
    { key: 'orderType', label: '单据类型' },
    { key: 'name', label: '商品名称' },
    { key: 'specification', label: '规格型号' },
    { key: 'productUnit', label: '单位' },
    { key: 'qty', label: '数量' },
    { key: 'invoiceLeftQty', label: '开票剩余数量' },
    { key: 'invoiceQtyNew', label: '本次开票数量' },
    { key: 'productCostTax', label: '含税单价' },
    { key: 'productCostTaxModify', label: '修改后含税单价' },
    { key: 'contain_tax_amount', label: '含税金额' },
    { key: 'un_contain_tax_amount', label: '不含税金额' },
    { key: 'unContainTaxDiscountAmount', label: '不含税折扣金额' },
    { key: 'tax_amount', label: '税额' },
    { key: 'discountTaxAmount', label: '折扣税额' },
    { key: 'tax_rate', label: '税率' },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref()
  const contain_tax_amount_total = ref(0)
  const un_contain_tax_amount_total = ref(0)
  const un_contain_tax_discount_amount_total = ref(0)
  const tax_amount_total = ref(0)
  const discount_tax_amount_total = ref(0)
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    callback(config.selected)
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    contain_tax_amount_total,
    un_contain_tax_amount_total,
    un_contain_tax_discount_amount_total,
    tax_amount_total,
    discount_tax_amount_total,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    isSortDirDesc,
    refListTable,
    refetchData,
  }
}
