<template>
  <b-card>
    <ValidationObserver ref="observer">
      <b-form id="invoiceApplyListForm" class="edit-form mt-2">
        <b-row>
          <b-col md="12">
            <div class="inner-card-title">基本信息</div>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="销售类型"
                label-for="sales_type"
                label-size="sm"
                class="mb-1 required"
            >

              <ValidationProvider rules="required" name="销售类型" #default="{ errors }">
                <v-select
                    id="sales_type"
                    :options="sales_type_select"
                    :clearable="false"
                    v-model="invoiceapply.sales_type"
                    @input="changeSelect('sales_type',$event)"
                    class="select-size-sm"
                    :disabled="viewFlag"
                    :reduce="option => option.value"
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="申请人"
                label-for="apply_name"
                label-size="sm"
                class="mb-1"
            >
              <v-select
                  id="apply_name"
                  label="title"
                  :options="applierArr"
                  v-model="applier"
                  :clearable="false"
                  :disabled="viewFlag"
                  @input="changeUser($event)"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="开票编号"
                label-for="invoice_no"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="invoice_no"
                  size="sm"
                  v-model="invoiceapply.invoice_no"
                  readonly
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="业务类型"
                label-for="invoice_business_type"
                label-size="sm"
                class="mb-1 required"
            >
              <ValidationProvider rules="required" name="业务类型" #default="{ errors }">
                <v-select
                    id="invoice_business_type"
                    :options="getCodeOptions('invoice_business_type')"
                    :clearable="false"
                    v-model="invoiceapply.invoice_business_type"
                    class="select-size-sm"
                    :disabled="viewFlag"
                    :reduce="option => option.value"
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="店铺团队"
                label-for="store_name"
                label-size="sm"
                class="mb-1 required"
            >
              <ValidationProvider rules="required" name="店铺团队" #default="{ errors }">
                <b-form-input
                    id="store_name"
                    size="sm"
                    v-model="invoiceapply.store_name"
                    @click="showSingleModal('店铺团队')"
                    placeholder="点击搜索"
                    :disabled="viewFlag"
                    :state="errors.length > 0 ? false:null"/>


                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="开票单位"
                label-for="our_company_name"
                label-size="sm"
                class="mb-1 required"
            >
              <ValidationProvider rules="required" name="开票单位" #default="{ errors }">
                <b-form-input
                    id="our_company_name"
                    size="sm"
                    v-model="invoiceapply.our_company_name"
                    @click="showSingleModal('开票单位')"
                    placeholder="点击搜索"
                    :disabled="cannotModify"
                    :state="errors.length > 0 ? false:null"/>


                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="是否收到上游供应商发票"
                label-for="is_getsupplyinvoice"
                label-size="sm"
                class="mb-1 required"
            >
              <ValidationProvider rules="required" name="是否收到上游供应商发票" #default="{ errors }">
                <v-select
                    id="is_getsupplyinvoice"
                    :options="getCodeOptions('yesno')"
                    :clearable="false"
                    v-model="invoiceapply.is_getsupplyinvoice"
                    class="select-size-sm"
                    :disabled="viewFlag"
                    :reduce="option => option.value"
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="预计回款时间"
                label-for="return_time"
                label-size="sm"
                class="mb-1"
            >
              <flat-pickr
                  id="return_time"
                  v-model="invoiceapply.return_time"
                  :disabled="viewFlag"
                  class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <div class="inner-card-title">开票信息</div>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="发票类型"
                label-for="invoice_apply_type"
                label-size="sm"
                class="mb-1 required"
            >
              <ValidationProvider rules="required" name="发票类型" #default="{ errors }">
                <v-select
                    id="invoice_apply_type"
                    :options="getCodeOptions('invoice_apply_type')"
                    :clearable="false"
                    v-model="invoiceapply.invoice_apply_type"
                    @input="changeSelect('invoice_apply_type',$event)"
                    class="select-size-sm"
                    :disabled="viewFlag"
                    :reduce="option => option.value"
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="收票地址"
                label-for="invoice_address"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="invoice_address"
                  size="sm"
                  :disabled="viewFlag"
                  v-model="invoiceapply.invoice_address"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="折扣金额"
                label-for="discount"
                label-size="sm"
                class="mb-1"
            >
              <ValidationProvider name="折扣金额" #default="{ errors }">
                <b-form-input
                    id="discount"
                    size="sm"
                    type="number"
                    v-model="invoiceapply.discount"
                    @change="changeDiscount($event)"
                    :disabled="viewFlag"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="是否散客"
                label-for="is_traveler"
                label-size="sm"
                class="mb-1 required"
            >
              <ValidationProvider rules="required" name="是否散客" #default="{ errors }">
                <v-select
                    id="is_traveler"
                    :options="getCodeOptions('yesno')"
                    :clearable="false"
                    v-model="invoiceapply.is_traveler"
                    @input="changeSelect('is_traveler',$event)"
                    class="select-size-sm"
                    :disabled="isDisabled"
                    :reduce="option => option.value"
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="4"
                 v-if="this.show_customer_name">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="客户名称"
                label-for="other_company_name"
                label-size="sm"
                :class="'mb-1 '+ isRequired"
            >
              <ValidationProvider :rules="isRequired" name="客户名称" #default="{ errors }">
                <b-form-input
                    id="other_company_name"
                    size="sm"
                    v-model="invoiceapply.other_company_name"
                    @click="showSingleModal('客户名称')"
                    placeholder="点击搜索"
                    :disabled="viewFlag"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="发票抬头"
                label-for="invoice_head"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="invoice_head"
                  size="sm"
                  :disabled="customerInfoFlag"
                  v-model="invoiceapply.invoice_head"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="纳税人识别号"
                label-for="tax_number"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="tax_number"
                  size="sm"
                  :disabled="customerInfoFlag"
                  v-model="invoiceapply.tax_number"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="地址、电话"
                label-for="tax_address"
                label-size="sm"
                :class="'mb-1 ' + invoiceRequired"
            >
                <b-form-input
                  id="tax_address"
                  size="sm"
                  :disabled="customerInfoFlag"
                  v-model="invoiceapply.tax_address"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="开户行及账号"
                label-for="tax_bankinfo"
                label-size="sm"
                :class="'mb-1 ' + invoiceRequired"
            >
                <b-form-input
                  id="tax_bankinfo"
                  size="sm"
                  :disabled="customerInfoFlag"
                  v-model="invoiceapply.tax_bankinfo"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <div class="inner-card-title">开票明细</div>
          </b-col>
          <b-col>
            <invoice-apply-item-list ref="itemList" :store_id="invoiceapply.store_id"
                                     :sales_type="invoiceapply.sales_type"
                                     :discount="invoiceapply.discount"
                                     :is_traveler="invoiceapply.is_traveler"
                                     :view_flag="this.viewFlag"
                                     :other_company_id="invoiceapply.other_company_id">

            </invoice-apply-item-list>
          </b-col>
          <b-col md="12">
            <div class="inner-card-title">其他信息</div>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="备注"
                label-for="remark"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="remark"
                  size="sm"
                  :disabled="viewFlag"
                  v-model="invoiceapply.remark"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件"
                label-for="attachments"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload v-if="invoiceapply.loaded":theme="'files'"
                                 :attachment_id="'attachments'"
                                 :id="invoiceapply.attachments"
                                 :object_type="'invoiceapply'"
                                 :object_id="invoiceapply.invoice_id"
                                 :readonly="viewFlag"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>

    <b-col
        cols="12"
        class="mt-50"
    >
      <b-button
          v-show="!viewFlag"
          variant="primary"
          class="mr-1"
          @click="save(0)"
      >
        保存
      </b-button>
      <b-button
          v-show="cancelFlag"
          variant="outline-secondary"
          class="mr-1"
          @click="cancel"
      >
        取消
      </b-button>
      <b-button
          v-show="!cancelFlag"
          variant="outline-secondary"
          class="mr-1"
          @click="$router.go(-1)"
      >
        返回
      </b-button>
      <b-button
          v-show="!viewFlag"
          variant="outline-success"
          class="mr-1"
          @click="save(10)"
      >
        提交
      </b-button>
      <b-button
          v-show="agreeFlag"
          variant="outline-success"
          class="mr-1"
          @click="changeStatus(1)"
      >
        同意
      </b-button>
      <b-button
          v-show="returnFlag"
          variant="outline-success"
          class="mr-1"
          @click="changeStatus(11)"
      >
        驳回
      </b-button>

    </b-col>

    <!--店铺-->
    <b-modal
        id="modal-single-store"
        ok-only
        ok-title="确认"
        @ok="onSelectStore"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择店铺团队'"
        ref="storeModal"
    >
      <store-select
          ref="storeSelectReceive" :sales_type="this.invoiceapply.sales_type"
      >
        <!--        v-on:table="fromChildrenReceive($event,'store')"-->
      </store-select>
    </b-modal>
    <!--客户名称-->
    <b-modal
        id="modal-single-customer"
        ok-only
        ok-title="确认"
        @ok="onSelectCustomer"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择客户名称'"
        ref="customerModal"
    >
      <company-select
          ref="customerSelectReceive" :company_type=6
      >
        <!--        v-on:table="fromChildrenReceive($event,'customer')"-->
      </company-select>
    </b-modal>
    <!--开票单位-->
    <b-modal
        id="modal-single-company"
        ok-only
        ok-title="确认"
        @ok="onSelectInvoice"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择开票单位'"
        ref="invoiceModal"
    >
      <company-select
          ref="invoiceSelectReceive" :company_type=2
      >
        <!--        v-on:table="fromChildrenReceive($event,'invoice')"-->
      </company-select>
    </b-modal>
    <!--/ form -->
    <b-modal
        id="loadingModal"
        centered
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        ref="loadingModal"
    >
      <p class="my-4">正在处理，请勿关闭浏览器!</p>
    </b-modal>
  </b-card>
</template>


<script>

import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {computed, onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
// Notification
import {useToast} from 'vue-toastification/composition'
import {getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty, isNumber, avatarText} from '@core/utils/filter'
import {second, toDate} from '@core/utils/dayjs/utils'
import flatPickr from 'vue-flatpickr-component'
import invoiceApplyItemList from '@/views/apps/invoiceapply/InvoiceApplyItemList.vue'
import axios from "@/libs/axios";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import invoiceApplyEditStore from "@/views/apps/invoiceapply/invoiceApplyEditStore";
import StoreSelect from "@/views/apps/invoiceapply/StoreSelect.vue";
import CompanySelect from "@/views/apps/invoiceapply/CompanySelect.vue";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BModal,
    BFormCheckbox,
    vSelect,
    useToast,
    flatPickr,
    AttachmentUpload,

    CompanySelect,
    StoreSelect,
    invoiceApplyItemList,
  },
  props: {
    id: {
      type: Number,
    },
    view_flag: {
      type: Boolean,
    },
    cancelFlag: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Number,
    },
  },
  data() {
    return {
      invoiceapply: ref({}),
      show_customer_name: false,
      isDisabled: false,
      viewFlag: false,
      agreeFlag: false,
      returnFlag: false,
      customerInfoFlag: false,
      editFlag: false,
      isRequired: "",
      invoiceRequired: "",
      cannotModify: true,
      sales_type_select: [
        {label: '线下', value: '1'},
        {label: '线上', value: '2'}
      ],
      applierArr:[],
      applier:{},
      currentUser:{},
    }
  },
  methods: {
    showSingleModal(type) {
      if (type === '店铺团队') {
        this.$refs['storeModal'].show()
      } else if (type === '开票单位') {
        this.$refs['invoiceModal'].show()
      } else if (type === '客户名称') {
        this.$refs['customerModal'].show()
      }
    },
    changeUser(event){
      this.invoiceapply.applier_id = event.id;
      this.invoiceapply.applier_name = event.title;
    },
    onSelectStore() {
      let returnData = {}
      if (this.$refs.storeSelectReceive.getSelected().length > 0) {
        returnData = this.$refs.storeSelectReceive.getSelected()[0]
        if(this.invoiceapply.sales_type === '1'){
          if(isEmpty(returnData.invoice_oa_userid)||isEmpty(returnData.invoice_oa_user_name)){
            alert("销售类型为线下，所选门店OA开票人未维护")
            return false;
          }else{
            this.invoiceapply.applier_id = returnData.invoice_oa_userid;
            this.invoiceapply.applier_name = returnData.invoice_oa_user_name;
            this.invoiceapply.our_company_id = returnData.returnmoney_companyid
            this.invoiceapply.our_company_name = returnData.returnmoney_companyname
          }
        }else{
          if(!isEmpty(returnData.invoice_oa_userid)&&!isEmpty(returnData.invoice_oa_user_name)){
            this.invoiceapply.applier_id = returnData.invoice_oa_userid;
            this.invoiceapply.applier_name = returnData.invoice_oa_user_name;
          }else{
            this.invoiceapply.applier_id = this.invoiceapply.current_user_id;
            this.invoiceapply.applier_name = this.invoiceapply.current_user_name;
          }
        }
        this.applier = {"title":this.invoiceapply.applier_name,"id":this.invoiceapply.applier_id}
        this.applierArr = [];
        this.applierArr.push(this.applier)
        if(this.invoiceapply.applier_id !== this.currentUser.id){
          this.applierArr.push(this.currentUser)
        }
        if(this.invoiceapply.store_id !== returnData.store_id){
          this.$refs.itemList.removeDetail();
        }
        this.invoiceapply.store_id = returnData.store_id
        this.invoiceapply.store_name = returnData.store_name
        /*this.invoiceapply.our_company_id = returnData.returnmoney_companyid
        this.invoiceapply.our_company_name = returnData.returnmoney_companyname*/
        this.$forceUpdate()
      }
    },
    onSelectCustomer() {
      let returnData = {}
      if (this.$refs.customerSelectReceive.getSelected().length > 0) {
        returnData = this.$refs.customerSelectReceive.getSelected()[0]
        if(this.invoiceapply.other_company_id !== (returnData.company_id).toString()){
          this.$refs.itemList.removeDetail();
        }
        this.invoiceapply.other_company_id = (returnData.company_id).toString()
        this.invoiceapply.other_company_name = returnData.company_name

        this.invoiceapply.invoice_head = returnData.invoice_head
        this.invoiceapply.tax_number = returnData.invoice_tax
        this.invoiceapply.tax_address = returnData.invoice_link_address
        this.invoiceapply.tax_bankinfo = returnData.invoice_bank_name
        this.customerInfoFlag = true;
        this.$forceUpdate()
      }
    },
    onSelectInvoice() {
      let returnData = {}
      if (this.$refs.invoiceSelectReceive.getSelected().length > 0) {
        returnData = this.$refs.invoiceSelectReceive.getSelected()[0]
        this.invoiceapply.our_company_id = returnData.company_id
        this.invoiceapply.our_company_name = returnData.company_name
        this.$forceUpdate()
      }
    },
    changeStatus: function (status) {
      this.$refs['loadingModal'].show()
      store.dispatch('invoiceapplyedit/changeStatus', {
        invoiceId: this.id,
        status:status,
        oldStatus:this.status,
      })
      .then(res => {
        this.$refs['loadingModal'].hide()
        if (res.data.code === 0) {
          this.$emit('refesh')
        } else {
          alert(res.data.data);
        }
      })
    },
    save: function (status) {
      //发票信息
      let obj = {};
      obj.invoice_id = this.invoiceapply.invoice_id;
      obj.sales_type = this.invoiceapply.sales_type;
      if(!obj.sales_type){
        alert("请选择销售类型" )
        return false;
        //toast.error("请选择销售类型")
      }
      obj.invoice_no = this.invoiceapply.invoice_no;
      obj.applier_id = this.invoiceapply.applier_id;
      obj.applier_name = this.invoiceapply.applier_name;
      obj.business_type = this.invoiceapply.invoice_business_type;
      if(!obj.business_type){
        //toast.error("请选择业务类型")
        alert("请选择业务类型" )
        return false;
      }
      obj.invoice_address = this.invoiceapply.invoice_address;
      if(!obj.invoice_address){
        alert("请填写收票地址" )
        return false;
      }
      obj.discount = this.invoiceapply.discount;
      obj.is_traveler = this.invoiceapply.is_traveler;
      if(!obj.is_traveler){
        alert("请选择是否散客" )
        return false;
      }
      obj.other_company_id = this.invoiceapply.other_company_id;
      obj.other_company_name = this.invoiceapply.other_company_name;

      if(obj.is_traveler === '0' && !obj.other_company_name){
        alert("散客,请选择客户" )
        return false;
      }
      obj.invoice_head = this.invoiceapply.invoice_head;
      if(!obj.invoice_head){
        alert("请填写发票抬头" )
        return false;
      }
      obj.tax_number = this.invoiceapply.tax_number;
      if(!obj.tax_number){
        alert("请填写纳税人识别号" )
        return false;
      }
      if(this.invoiceapply.invoice_apply_type === '1'){
        if(isEmpty(this.invoiceapply.tax_address)){
          alert("专用发票，地址、电话必填")
          return false;
        }
        if(isEmpty(this.invoiceapply.tax_bankinfo)){
          alert("专用发票，开户行及账号必填")
          return false;
        }
      }
      obj.tax_address = this.invoiceapply.tax_address;
      obj.tax_bankinfo = this.invoiceapply.tax_bankinfo;
      obj.store_id = this.invoiceapply.store_id;
      obj.store_name = this.invoiceapply.store_name;
      if(!obj.store_name){
        alert("请选择店铺团队")
        return false;
      }
      obj.our_company_id = this.invoiceapply.our_company_id;
      obj.our_company_name = this.invoiceapply.our_company_name;
      if(!obj.our_company_name){
        alert("请选择开票单位")
        return false;
      }
      obj.invoice_apply_type = this.invoiceapply.invoice_apply_type;
      if(!obj.invoice_apply_type){
        alert("请选择发票类型")
        return false;
      }
      obj.is_getsupplyinvoice = this.invoiceapply.is_getsupplyinvoice;
      if(!obj.is_getsupplyinvoice){
        alert("是否收到上游供应商发票")
        return false;
      }
      obj.return_time = second(this.invoiceapply.return_time);
      obj.remark = this.invoiceapply.remark;
      obj.attachments = this.invoiceapply.attachments;
      obj.status = status;
      obj.state = 1;

      //明细
      let selected = this.$refs.itemList.getDetails();
      if(selected.length <= 0){
        alert("请先添加明细");
        return false;
      }
      let details = []
      //深拷贝，修改拷贝后对象的值，不影响原来对象
      for (let j = 0; j < selected.length; j++) {
        let ob = {};
        ob = JSON.parse(JSON.stringify(selected[j]));
        details.push(ob)
      }
      let sum = 0;
      let arr=[];
      for (let j = 0; j < details.length; j++) {
        if(details[j].order_type.indexOf('退货')>-1){
          sum = this.accSub(sum,details[j].contain_tax_amount);
        }else{
          sum = this.accAdd(sum,details[j].contain_tax_amount);
        }
        console.log("sum")
        console.log(sum)
        details[j].item_id = null;
        details[j].qty = details[j].invoice_qty_new;
        let object = {}
        object.invoice_item_id = details[j].invoice_item_id;
        object.order_type = details[j].order_type;
        object.order_id = details[j].order_id;
        object.product_id = details[j].product_id;
        object.product_cost_tax_str = details[j].product_cost_tax_str;
        object.invoice_qty = details[j].invoice_qty_new;
        arr.push(object)
      }
      if(sum<=0){
        alert("含税金额之和需大于0")
        return false;
      }
      if(!isEmpty(this.invoiceapply.discount)&& sum < isNumber(this.invoiceapply.discount)){
        alert("含税金额之和小于折扣金额")
        return false;
      }
      let paramCheck = {}
      paramCheck.invoiceDetailStr = JSON.stringify(arr)
      // return false;

      this.$refs['loadingModal'].show()
      store.dispatch('invoiceapplyedit/checkList', paramCheck).then(res => {
        if (res.data.code==0){
          for (let j = 0; j < details.length; j++) {
            if(details[j].order_type === '线下销售单'){
              details[j].order_type = 1;
            }else if(details[j].order_type === '线上销售单'){
              details[j].order_type = 2;
            }else if(details[j].order_type === '退货单'){
              details[j].order_type = 3;
            }
          }
          let param={}
          param.invoiceStr = JSON.stringify(obj)
          param.invoiceDetailStr = JSON.stringify(details)
          store.dispatch('invoiceapplyedit/save', param).then(res => {
            this.$refs['loadingModal'].hide()
            if (res.data.code==0){
              alert("数据已保存!");
              //toast.success('数据已保存!')
              // this.$router.push({ name: 'apps-invoice-apply-list'});
              if(!this.cancelFlag){
                this.$router.push({ name: 'apps-invoice-apply-list'});
              }else{
                this.$emit('refesh')
              }
            }else {
              alert(res.data.data);
            }
          })
        }else {
          this.$refs['loadingModal'].hide()
          alert(res.data.data);
        }
      })
    },
    changeSelect(key, value) {
      if (key === 'sales_type') {
        //
        store.dispatch('invoiceapplyedit/changeSalesType', {sales_type: value}).then(res => {
          let data = res.data.data;
          if(data.sales_type === 2){
            this.invoiceapply.sales_type = '2'
          }else{
            this.invoiceapply.sales_type = '1'
          }
          //销售类型切换成功，删除明细
          if(value === this.invoiceapply.sales_type){
            this.$refs.itemList.removeDetail();
            this.invoiceapply.other_company_id = "";
            this.invoiceapply.other_company_name = "";
            this.invoiceapply.invoice_head = ""
            this.invoiceapply.tax_number = ""
            this.invoiceapply.tax_address = ""
            this.invoiceapply.tax_bankinfo = ""
            this.customerInfoFlag = false;

          }
          //销售类型是线下   销售类型为线下   是否散客默认为空  客户名称隐藏
          if (this.invoiceapply.sales_type === '1') {
            this.invoiceapply.is_traveler = "";

            this.isDisabled = false;

            this.show_customer_name = false;
            this.isRequired = "";
          } else {
            //销售类型是线上   是否散客默认为是且不可修改  客户名称影藏
            this.invoiceapply.is_traveler = '1';
            this.isDisabled = true;

            this.show_customer_name = false;
            this.isRequired = "";
          }
          this.invoiceapply.applier_id = data.applier_id;
          this.invoiceapply.applier_name = data.applier_name;

          this.applier = {"title":this.invoiceapply.applier_name,"id":this.invoiceapply.applier_id}
          this.applierArr = [];
          this.applierArr.push(this.applier)
          if(data.applier_id !== this.currentUser.id){
            this.applierArr.push(this.currentUser)
          }

          this.invoiceapply.store_id = data.store_id;
          this.invoiceapply.store_name = data.store_name;
          this.invoiceapply.our_company_id = data.our_company_id;
          this.invoiceapply.our_company_name = data.our_company_name;
          if (value === '1' && this.invoiceapply.sales_type !== '1') {
            alert("OA开票申请人不存在，销售类型不可为线下")
            // return false;
          }
        })
      } else if (key === 'is_traveler') {
        //散客
        this.invoiceapply.other_company_id = "";
        this.invoiceapply.other_company_name = "";
        this.invoiceapply.invoice_head = ""
        this.invoiceapply.tax_number = ""
        this.invoiceapply.tax_address = ""
        this.invoiceapply.tax_bankinfo = ""
        this.customerInfoFlag = true;
        this.$refs.itemList.removeDetail();
        if (value === '1') {
          this.show_customer_name = false;
          this.customerInfoFlag = false;
          this.isRequired = "";
        } else if (value === '0') {
          this.customerInfoFlag = true;
          //非散客
          this.show_customer_name = true;
          this.isRequired = "required";
          //空
        } else {
          this.customerInfoFlag = false;
          this.show_customer_name = false;
          this.isRequired = "";
        }
      }else if(key === 'invoice_apply_type'){
        if(value === '1'){
          this.invoiceRequired = 'required'
        }else{
          this.invoiceRequired = ""
        }
      }
    },
    cancel: function () {
      this.$emit('cancelEdit')
    },
  },
  setup() {
    // Register module
    if (!store.hasModule('invoiceapplyedit')) store.registerModule('invoiceapplyedit', invoiceApplyEditStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('invoiceapplyedit')) store.unregisterModule('invoiceapplyedit')
    })

    const edit = function (invoiceId,viewFlag,status) {
      //默认线下
      store.dispatch('invoiceapplyedit/initEdit', {salesType: 1,invoiceId:invoiceId}).then(res => {
        if (res.data.data.role_id === 12 || res.data.data.role_id === 14) {//角色财务主管、系统超管角色
          this.cannotModify = false;
        }
        if ((res.data.data.role_id === 12 || res.data.data.role_id === 14||res.data.data.current_user_id===res.data.data.creator)&&status ===10) {//角色财务主管、系统超管角色
          this.returnFlag = true;
        }
        if ((res.data.data.role_id === 12 || res.data.data.role_id === 14)&&status ===10) {//角色财务主管、系统超管角色
          this.agreeFlag = true;
        }

        if(invoiceId === undefined || invoiceId === 0){
          //发票申请人
          this.currentUser = {"title":res.data.data.current_user_name,"id":res.data.data.current_user_id}
          this.applier = {"title":res.data.data.applier_name,"id":res.data.data.applier_id}
          this.applierArr.push(this.applier)
          if(res.data.data.applier_id !== this.currentUser.id){
            this.applierArr.push(this.currentUser)
          }
          this.invoiceapply = res.data.data
          if(res.data.data.sales_type === 2){
            this.invoiceapply.sales_type = '2'
          }else{
            this.invoiceapply.sales_type = '1'
          }

          if (this.invoiceapply.sales_type === "1") {
            this.invoiceapply.is_traveler = "";
            this.isDisabled = false;
            this.show_customer_name = false;
            this.isRequired = "";
          } else {
            this.invoiceapply.is_traveler = '1';
            this.isDisabled = true;
            this.show_customer_name = false;
            this.isRequired = "";
          }
        }else{
          //编辑查看页面
          //发票申请人
          if(res.data.data.ext != undefined){
            if(res.data.data.ext.oaUserId && res.data.data.ext.creatorId){
              if(res.data.data.ext.oaUserId === res.data.data.ext.creatorId){
                let oaUser = {"title":res.data.data.ext.oaUserName,"id":res.data.data.ext.oaUserId}
                this.applierArr.push(oaUser)
              }else{
                let oaUser = {"title":res.data.data.ext.oaUserName,"id":res.data.data.ext.oaUserId}
                this.applierArr.push(oaUser)
                let creator = {"title":res.data.data.ext.creatorName,"id":res.data.data.ext.creatorId}
                this.applierArr.push(creator)
              }
            }else{
              if(res.data.data.ext.oaUserId){
                let oaUser = {"title":res.data.data.ext.oaUserName,"id":res.data.data.ext.oaUserId}
                this.applierArr.push(oaUser)
              }
              if(res.data.data.ext.creatorId){
                let creator = {"title":res.data.data.ext.creatorName,"id":res.data.data.ext.creatorId}
                this.applierArr.push(creator)
              }
            }
          }
          this.invoiceapply = res.data.data
          this.applier = {"title":this.invoiceapply.applier_name,"id":this.invoiceapply.applier_id}
          this.invoiceapply.return_time = toDate(res.data.data.return_time)
          this.$refs.itemList.fillDetail(res.data.data.ext.items,res.data.data.discount)
          if (this.invoiceapply.sales_type === "1") {
            this.isDisabled = false;
            if(this.invoiceapply.is_traveler === '1'){
              this.show_customer_name = false;
              this.isRequired = "";
            }else if(this.invoiceapply.is_traveler === '0'){
              this.show_customer_name = true;
              this.isRequired = "required";
            }else{
              this.show_customer_name = false;
              this.isRequired = "";
            }
          } else {
            // this.invoiceapply.is_traveler = '1';
            this.isDisabled = true;
            this.show_customer_name = false;
            this.isRequired = "";
          }
          //this.editFlag = true;
          //页面功能禁用
          if(viewFlag){
            this.viewFlag = viewFlag;
            this.cannotModify = viewFlag;
            this.customerInfoFlag = viewFlag;
            this.isDisabled = viewFlag;
          }
        }
      })
    }

    const accAdd= function (arg1,arg2) {
      let r1,r2,m;
      try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
      try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
      m=Math.pow(10,Math.max(r1,r2))
      return (arg1*m+arg2*m)/m;
    }
    const accSub= function (arg1,arg2) {
      let r1,r2,m,n;
      try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
      try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
      m=Math.pow(10,Math.max(r1,r2));
      n=(r1>=r2)?r1:r2;
      return ((arg1*m-arg2*m)/m).toFixed(n);
    }
    /*const fromChildrenReceive = function (data,modal) {
      if (modal=='store'){
        this.invoiceapply.store_id = checked.store_id
        this.invoiceapply.store_name = checked.store_name
        this.invoiceapply.our_company_id = checked.returnmoney_companyid
        this.invoiceapply.our_company_name = checked.returnmoney_companyname


        this.$forceUpdate()
      }
      this.$bvModal.hide('modal-single')
    }*/

    const toast = useToast()
    const onUploaded = function (id, attachment, result) {
      this.invoiceapply[id] = result
    }
    const changeDiscount= function (dis){
      /*if(isNumber(dis)<0){
        dis = isNumber(0)
        this.invoiceapply.discount = 0
      }*/
      this.$refs.itemList.changeDis(dis)
    }
    return {
      /*fromChildrenReceive,*/
      // Filter
      toast,
      edit,
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onUploaded,
      accAdd,
      accSub,
      changeDiscount,

    }
  },
  created() {
    let invoice_id = this.id || 0;
    let view_flag = this.view_flag || false;
    let status = this.status || 99;
    this.edit(invoice_id,JSON.parse(view_flag),status);
  },
}
</script>
