import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function invoiceApplyItemSelectList(config) {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: '#', label: '#'},
    { key: 'orderNo', label: '单据编号' },
    { key: 'sale_return_date', label: '销售/退货日期' },
    { key: 'channelName', label: '客户/渠道' },
    { key: 'orderType', label: '单据类型' },
    { key: 'qty', label: '数量' },
    { key: 'containTaxAmount', label: '含税金额' },
    //{ key: 'invoiceRemainAmount', label: '开票剩余额度' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref(" a.addTime desc,a.id desc ")
  const isSortDirDesc = ref(false)
  const productName = ref('')
  const code = ref('')
  const storeName = ref('')
  const orderNo = ref('')
  const orderType = ref()
  const saleReturnDateStr = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const searchList = (ctx, callback) => {
      if(config.sales_type==='1'&&isEmpty(productName.value)
          &&isEmpty(code.value)&&isEmpty(orderNo.value)&&isEmpty(orderType.value)
          &&isEmpty(storeName.value)&&isEmpty(saleReturnDateStr.value)){
          toast.error("销售类型为线下，需先选择筛选条件再查询！")
          callback([])
      }else{
          store
              .dispatch('invoiceApplyItemSelectList/listOrder', {
                  productName: productName.value,
                  code: code.value,
                  storeName: storeName.value,
                  orderNo: orderNo.value,
                  orderType: orderType.value,
                  saleReturnDateStr: saleReturnDateStr.value,
                  storeId:config.store_id,
                  salesType:config.sales_type==='1'?'线下':'线上',
                  otherCompanyId:config.other_company_id,
                  isTraveler:config.is_traveler,
                  selectedStr:config.selected_str,
                  start: start.value,
                  limit: limit.value,
                  order_by: orderBy.value,
                  order_desc: isSortDirDesc.value === true ? 'desc':'',
              })
              .then(response => {
                  const data = response.data.data
                  const list = data.ext.list
                  listTotals.value = data.ext.totals

                  callback(list)
              })
              .catch((e) => {
                  toast({
                      component: ToastificationContent,
                      props: {
                          title: '明细列表获取错误',
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                      },
                  })
              })
      }

  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,
    productName,
    code,
    storeName,
    orderNo,
    orderType,
    saleReturnDateStr,
  }
}
