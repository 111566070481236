<template>
  <div>
    <b-card
        no-body
        class="mb-0 list-table"
    >
      <div class="m-2">
        <b-row>
          <b-col
              cols="12"
              md="6"
          >
            <b-button
                v-show="!view_flag"
                variant="outline-primary"
                v-b-modal.modal-select-product
                @click="showDetail()"
                class="mr-1"
            >
              <span class="text-nowrap">添加明细</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <ValidationObserver ref="observerItemList">
        <b-table
            sticky-header
            noCollapse
            ref="refListTable"
            class="position-relative"
            :items="searchList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="orderBy"
            show-empty
            empty-text="未找到记录"
            :sort-desc.sync="isSortDirDesc"
            v-model="dataList"
        >
          <template v-slot:custom-foot="data">
            <b-tr>
              <b-td :colspan="5" variant="primary">
                <span><strong></strong></span>
              </b-td>
              <b-td :colspan="1" variant="primary">
                <span><strong>合计</strong></span>
              </b-td>
              <b-td :colspan="5" variant="primary">
                <span><strong></strong></span>
              </b-td>
              <b-td :colspan="1" variant="primary">
                <span><strong>{{contain_tax_amount_total}}</strong></span>
              </b-td>
              <b-td :colspan="1" variant="primary">
                <span><strong>{{un_contain_tax_amount_total}}</strong></span>
              </b-td>
              <b-td :colspan="1" variant="primary">
                <span><strong>{{un_contain_tax_discount_amount_total}}</strong></span>
              </b-td>
              <b-td :colspan="1" variant="primary">
                <span><strong>{{tax_amount_total}}</strong></span>
              </b-td>
              <b-td :colspan="1" variant="primary">
                <span><strong>{{discount_tax_amount_total}}</strong></span>
              </b-td>
              <b-td :colspan="2" variant="primary">
                <span><strong></strong></span>
              </b-td>
            </b-tr>
          </template>
          <!-- Columns -->
          <template #cell(no)="data">
            {{data.item.no}}
          </template>
          <template #cell(orderNo)="data">
            {{data.item.order_no}}
          </template>
          <template #cell(orderType)="data">
            {{data.item.order_type}}
          </template>
          <template #cell(name)="data">
            {{data.item.name}}
          </template>
          <template #cell(specification)="data">
            {{data.item.specification}}
          </template>
          <template #cell(productUnit)="data">
            {{getCodeLabel('product_unit',data.item.product_unit)}}
          </template>
          <template #cell(qty)="data">
            {{data.item.qty>0?data.item.symbol:''}}{{data.item.qty}}
          </template>
          <template #cell(invoiceLeftQty)="data">
            {{data.item.invoice_left_qty}}
          </template>
          <template #cell(invoiceQtyNew)="data">
            <b-form-input
                size="sm"
                v-model="data.item.invoice_qty_new"
                type="number"
                style="width: 80px"
                :readonly="view_flag||sales_type==='1'"
                @change="changeQty(data.item,$event)"
            />
          </template>
          <template #cell(productCostTax)="data">
            {{data.item.product_cost_tax}}
          </template>
          <template #cell(productCostTaxModify)="data">
            <b-form-input
                size="sm"
                v-model="data.item.product_cost_tax_modify"
                style="width: 80px"
                type="number"
                :readonly="view_flag||sales_type==='1'"
                @change="changeCost(data.item,$event)"
            />
          </template>
          <template #cell(contain_tax_amount)="data">
            {{data.item.contain_tax_amount>0?data.item.symbol:''}}{{data.item.contain_tax_amount}}
          </template>
          <template #cell(un_contain_tax_amount)="data">
            {{data.item.un_contain_tax_amount>0?data.item.symbol:''}}{{data.item.un_contain_tax_amount}}
          </template>
          <template #cell(unContainTaxDiscountAmount)="data">
<!--            {{data.item.un_contain_tax_discount_amount>0?data.item.symbol:''}}{{data.item.un_contain_tax_discount_amount}} -->
            {{ (data.item.un_contain_tax_discount_amount>0?data.item.symbol:(data.item.un_contain_tax_discount_amount==0?'':(data.item.symbol=='-'?'':'-')))=='-'?0-Math.abs(data.item.un_contain_tax_discount_amount):Math.abs(data.item.un_contain_tax_discount_amount)}}
          </template>
          <template #cell(tax_amount)="data">
            {{data.item.tax_amount>0?data.item.symbol:''}}{{data.item.tax_amount}}
          </template>
          <template #cell(discountTaxAmount)="data">
<!--            {{data.item.discount_tax_amount>0?data.item.symbol:''}}{{data.item.discount_tax_amount}}-->
            {{ (data.item.discount_tax_amount>0?data.item.symbol:(data.item.discount_tax_amount==0?'':(data.item.symbol=='-'?'':'-')))=='-'?0-Math.abs(data.item.discount_tax_amount):Math.abs(data.item.discount_tax_amount)}}
          </template>
          <template #cell(tax_rate)="data">
            {{data.item.tax_rate_str}}
          </template>
          <template #cell(actions)="data">
<!--            <feather-icon icon="EditIcon" />-->
            <span  v-show="!view_flag" class="align-middle ml-50" @click="deleteDetail(data)"><feather-icon icon="DeleteIcon"/></span>
          </template>


        </b-table>
      </ValidationObserver>
<!--      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          &lt;!&ndash; Pagination &ndash;&gt;
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>-->
      <b-modal
          v-if="isShowDetail"
          id="modal-select-product"
          ok-only
          ok-title="确认"
          @ok="onSelectDateil"
          cancel-title="取消"
          centered
          size="xl"
          title="选择明细"
      >
        <invoice-apply-item-select-list
            ref="invoiceApplyItemSelect"
            :store_id="store_id"
            :sales_type="sales_type"
            :selected_str="detail_str"
            :is_traveler="is_traveler"
            :other_company_id="other_company_id"
        >
        </invoice-apply-item-select-list>
      </b-modal>
    </b-card>

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty,
  isNumber
} from '@core/utils/filter'
import invoiceApplyItemList from './invoiceApplyItemList'
import {useToast} from 'vue-toastification/composition'
import invoiceApplyEditStore from "@/views/apps/invoiceapply/invoiceApplyEditStore";
import invoiceApplyItemSelectList from "@/views/apps/invoiceapply/InvoiceApplyItemSelectList.vue";

export default {
  components: {
    invoiceApplyItemSelectList,

    BModal,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      isShowDetail:false,
      dataList: [],
      detail_str: "",
    }
  },
  methods: {
    validateRow : async function (index) {
      let refs = this.$refs.observerItemList.refs
      let validArr = []
      for (const key in refs) {
        if (refs.hasOwnProperty(key) && key.includes(index)) {
          let validate = await refs[key].validate()
          if (!validate['valid']) {
            validArr.push(key)
          }
        }
      }
      return validArr.length === 0
    },
    getDetails: function () {
      return this.dataList
    },
    async validateAll() {
      let count = 0
      let items = this.dataList
      for (let i = 0; i < items.length; i++) {
        if (await this.$refs[`observer_qty_${i}`].validate() && await this.$refs[`observer_cost_${i}`].validate()) {
          count++
        }
      }
      return count === items.length
    },


  },
  props: {
    store_id: {
      type: Number
    },
    sales_type: {
      type: String
    },
    is_traveler: {
      type: String
    },
    other_company_id: {
      type: String
    },
    discount: {
      type: String,
      default: "0"
    },
    view_flag: {
      type: Boolean,
      default: false
    },
  },
  setup(props, { emit }) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('invoiceapplyitem')) store.registerModule('invoiceapplyitem', invoiceApplyEditStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('invoiceapplyitem')) store.unregisterModule('invoiceapplyitem')
    })

    const showDetail = function () {
      if(isEmpty(props.store_id)){
        this.isShowDetail = false;
        alert("未选择店铺团队!")
        return false;
      }
      if(props.sales_type === '1' && isEmpty(props.is_traveler)){
        this.isShowDetail = false;
        alert("销售类型为线下时，是否散客必选!")
        return false;
      }
      if(props.sales_type === '1' && props.is_traveler === '0' && isEmpty(props.other_company_id)){
        this.isShowDetail = false;
        alert("销售类型为线下且非散客时，客户名称必选!")
        return false;
      }else{
        //过滤已选数据
        let items = this.dataList
        let receiveQty = []
        if(items.length > 0){
          for (let i = 0; i < items.length; i++) {
            let type = "";
            if(items[i].order_type.indexOf("销售")>-1){
              type = "销售"
            }else{
              type = "退货"
            }
            receiveQty.push("'"+type+"_"+items[i].order_id+"_"+items[i].product_id+"_"+items[i].product_cost_tax_str+"'");
          }
          this.detail_str = receiveQty.join(",");
        }
        this.isShowDetail = true;
      }
    }


    const removeDetail= function () {
      this.dataList.splice(0,this.dataList.length);
      this.$forceUpdate()
      contain_tax_amount_total.value = 0;
      un_contain_tax_amount_total.value = 0;
      un_contain_tax_discount_amount_total.value = 0;
      tax_amount_total.value = 0;
      discount_tax_amount_total.value = 0;
    }

    const changeQty= function (data,newQty){
      let r = /^[1-9]\d*$/;
      if(!r.test(newQty)){
        alert("开票数量必须为正整数")
        data.invoice_qty_new = data.invoice_left_qty;
        this.$forceUpdate()
        return false;
      }
      if(data.invoice_left_qty<newQty){
        alert("本次开票数量不可大于开票剩余数量")
        data.invoice_qty_new = data.invoice_left_qty;
        this.$forceUpdate()
        return false;
      }else{
        data.contain_tax_amount = (isNumber(data.invoice_qty_new)*isNumber(data.product_cost_tax_modify)).toFixed(6);
        data.tax_amount = (isNumber(data.contain_tax_amount)/isNumber((1+isNumber(data.tax_rate)))*isNumber(data.tax_rate)).toFixed(6);
        data.un_contain_tax_amount = (isNumber(data.contain_tax_amount)-isNumber(data.tax_amount)).toFixed(6);
        this.changeDis(this.discount);
      }
    }
    const changeCost= function (data,productCostTaxModify){
      // if(!(/^\d{1,3}(.[0-9]{2})?$/.test(productCostTaxModify))){
      if(isEmpty(productCostTaxModify)){
        data.product_cost_tax_modify = data.product_cost_tax_modify_new;
        this.$forceUpdate()
        return false;
      }else if(productCostTaxModify < 0){
        data.product_cost_tax_modify = data.product_cost_tax_modify_new;
        this.$forceUpdate()
        return false;
      }else{
        data.contain_tax_amount = (isNumber(data.invoice_qty_new)*isNumber(data.product_cost_tax_modify)).toFixed(6);
        data.tax_amount = (isNumber(data.contain_tax_amount)/isNumber((1+isNumber(data.tax_rate)))*isNumber(data.tax_rate)).toFixed(6);
        data.un_contain_tax_amount = (isNumber(data.contain_tax_amount)-isNumber(data.tax_amount)).toFixed(6);
        this.changeDis(this.discount);
      }
    }

    const changeDis = function(discou){
      if(isEmpty(discou)){
        discou = 0;
      }
      let details = this.dataList;
      if(details.length >0 ){
        let discountNum = isNumber(discou);
        let sum = 0;
        for(let i=0;i<details.length;i++){
          if(details[i].contain_tax_amount){
            if(details[i].order_type.indexOf('退货')>-1){
              sum -= isNumber(details[i].contain_tax_amount);
            }else{
              sum += isNumber(details[i].contain_tax_amount);
            }

          }
        }
        if(discountNum === 0 ||sum < discountNum){
          for(let i=0;i<details.length;i++){
            details[i].un_contain_tax_discount_amount = 0
            details[i].discount_tax_amount = 0
          }
        }else{
          for(let i=0;i<details.length;i++){
            details[i].un_contain_tax_discount_amount = (discountNum/sum*isNumber(details[i].un_contain_tax_amount))
            details[i].discount_tax_amount = (discountNum/sum*isNumber(details[i].tax_amount))
            details[i].un_contain_tax_discount_amount = isNumber(details[i].un_contain_tax_discount_amount).toFixed(6)
            details[i].discount_tax_amount = isNumber(details[i].discount_tax_amount).toFixed(6)
          }
        }

        contain_tax_amount_total.value = 0;
        un_contain_tax_amount_total.value = 0;
        un_contain_tax_discount_amount_total.value = 0;
        tax_amount_total.value = 0;
        discount_tax_amount_total.value = 0;

        for (let i = 0; i < details.length; i++) {
          if(details[i].order_type.indexOf('退货')>-1){
            contain_tax_amount_total.value = accSub(contain_tax_amount_total.value,details[i].contain_tax_amount);
            un_contain_tax_amount_total.value = accSub(un_contain_tax_amount_total.value,details[i].un_contain_tax_amount);
            un_contain_tax_discount_amount_total.value = accSub(un_contain_tax_discount_amount_total.value,details[i].un_contain_tax_discount_amount);
            tax_amount_total.value = accSub(tax_amount_total.value,details[i].tax_amount);
            discount_tax_amount_total.value = accSub(discount_tax_amount_total.value,details[i].discount_tax_amount);
          }else{
            contain_tax_amount_total.value = accAdd(contain_tax_amount_total.value,details[i].contain_tax_amount);
            un_contain_tax_amount_total.value = accAdd(un_contain_tax_amount_total.value,details[i].un_contain_tax_amount);
            un_contain_tax_discount_amount_total.value = accAdd(un_contain_tax_discount_amount_total.value,details[i].un_contain_tax_discount_amount);
            tax_amount_total.value = accAdd(tax_amount_total.value,details[i].tax_amount);
            discount_tax_amount_total.value = accAdd(discount_tax_amount_total.value,details[i].discount_tax_amount);
          }
          details[i].no = accAdd(i,1);
        }
        this.refListTable.value =details
        this.$forceUpdate()
        contain_tax_amount_total.value = parseFloat(contain_tax_amount_total.value).toFixed(6);
        un_contain_tax_amount_total.value = parseFloat(un_contain_tax_amount_total.value).toFixed(6);
        un_contain_tax_discount_amount_total.value = parseFloat(un_contain_tax_discount_amount_total.value).toFixed(6);
        tax_amount_total.value = parseFloat(tax_amount_total.value).toFixed(6);
        discount_tax_amount_total.value = parseFloat(discount_tax_amount_total.value).toFixed(6);
      }else{
        contain_tax_amount_total.value = 0;
        un_contain_tax_amount_total.value = 0;
        un_contain_tax_discount_amount_total.value = 0;
        tax_amount_total.value = 0;
        discount_tax_amount_total.value = 0;
      }
    }
    const accAdd= function (arg1,arg2) {
      let r1,r2,m;
      try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
      try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
      m=Math.pow(10,Math.max(r1,r2))
      return (arg1*m+arg2*m)/m;
    }
    const accDiv= function (arg1,arg2) {
      let t1=0,t2=0,r1,r2;
      try{t1=arg1.toString().split(".")[1].length}catch(e){}
      try{t2=arg2.toString().split(".")[1].length}catch(e){}
      r1=Number(arg1.toString().replace(".",""))
      r2=Number(arg2.toString().replace(".",""))
      return accMul((r1/r2),Math.pow(10,t2-t1));
    }
    const accMul= function (arg1,arg2) {
      let m=0,s1=arg1.toString(),s2=arg2.toString();
      try{m+=s1.split(".")[1].length}catch(e){}
      try{m+=s2.split(".")[1].length}catch(e){}
      return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m);
    }
    const accSub= function (arg1,arg2) {
      let r1,r2,m,n;
      try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
      try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
      m=Math.pow(10,Math.max(r1,r2));
      n=(r1>=r2)?r1:r2;
      return ((arg1*m-arg2*m)/m).toFixed(n);
    }

    const fillDetail= function (arr,discount) {
      this.dataList.splice(0,this.dataList.length);
      let details = this.dataList;
      for(let i=0;i<arr.length;i++){
        details.push(arr[i])
      }
      refListTable.value.localItems = details
      this.changeDis(discount);
      this.$forceUpdate()
    }

    const onSelectDateil = function () {
      let selected = this.$refs.invoiceApplyItemSelect.getSelected();
      if(selected.length >0 ){
        let arr=[];
        for(let i=0;i<selected.length;i++){
          let obj = {}
          obj.order_type = selected[i].order_type;
          obj.order_id = selected[i].order_id;
          obj.product_id = selected[i].product_id;
          obj.product_cost_tax_str = selected[i].product_cost_tax_str;
          obj.invoice_qty = selected[i].invoice_left_qty;
          arr.push(obj)
        }
        let paramCheck = {}
        paramCheck.invoiceDetailStr = JSON.stringify(arr)
        store.dispatch('invoiceapplyedit/checkList', paramCheck).then(res => {
          if (res.data.code==0){
            let details = this.dataList;
            for(let i=0;i<selected.length;i++){
              details.push(selected[i])
            }
            refListTable.value.localItems = details
            this.changeDis(this.discount);
          }else {
            alert(res.data.data);
          }
        })
      }
    }

    const deleteDetail = function (row) {
      if(props.sales_type === '2'){
        let index = row.index;
        this.refListTable.value.splice(index, 1);
        this.changeDis(this.discount);
        this.$forceUpdate()
      }else{
        let res = confirm('线下开票只能整条单据删除，是否继续？')
        if (res) {
          for(let i=0;i<this.dataList.length;i++){
            if(this.dataList[i].order_no === row.item.order_no){
              this.dataList.splice(i,1);
              i-=1;
              this.$forceUpdate()
            }
          }
          this.$forceUpdate()
          this.changeDis(this.discount);
        }
      }
      //过滤已选数据
      let items = this.dataList
      let receiveQty = []
      if(items.length > 0){
        for (let i = 0; i < items.length; i++) {
          let type = "";
          if(items[i].order_type.indexOf("销售")>-1){
            type = "销售"
          }else{
            type = "退货"
          }
          receiveQty.push("'"+type+"_"+items[i].order_id+"_"+items[i].product_id+"_"+items[i].product_cost_tax_str+"'");
        }
        this.detail_str = receiveQty.join(",");
      }

    }



    const del = async function (item_id,index) {
      let res = confirm('确定删除吗？')
      if (res) {
        const res = await store.dispatch('purchaseorderitem/del', {
          id: item_id
        })
        if (res.data.code === 0) {
          toast.success(res.data.data)
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      }

    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      contain_tax_amount_total,
      un_contain_tax_amount_total,
      un_contain_tax_discount_amount_total,
      tax_amount_total,
      discount_tax_amount_total,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = invoiceApplyItemList({
    })



    return {
      showDetail,
      onSelectDateil,
      fillDetail,
      accAdd,
      accDiv,
      accMul,
      accSub,
      changeQty,
      removeDetail,
      changeCost,
      changeDis,
      deleteDetail,
      searchList,
      tableColumns,
      limit,
      start,
      contain_tax_amount_total,
      un_contain_tax_amount_total,
      un_contain_tax_discount_amount_total,
      tax_amount_total,
      discount_tax_amount_total,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,

      // page
      del,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
