import axios from '@axios'

export default {
  namespaced: true,
  state: {
    condition:undefined,
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    updateCondition(state,condition){
      state.condition = condition
    }
  },
  actions: {
    initEdit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/invoiceapply/initEdit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeSalesType(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/invoiceapply/changeSalesType', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    listStore(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/invoiceapply/listStore', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    listCompany(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/invoiceapply/listCompany', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    listInvoiceDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/invoiceapply/listInvoiceDetail', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    listOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/exportexcelfile/listOrder', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/invoiceapply/save', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    checkList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/invoiceapply/checkList', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/invoiceapply/changeStatus', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

  },
}
