<template>
  <div>
    <b-card
        no-body
        class="mb-0 list-table"
    >
      <div>
        <b-row>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
<!--            <b-alert
                variant="primary"
                show
            >
              <div class="alert-body">
                <span><strong>已勾选:{{
                    checked.length
                  }}  合计数量:{{ checked.reduce((sum, e) => sum + Number(e.total_qty || 0), 0) }}</strong> </span>
              </div>
            </b-alert>-->
          </b-col>
        </b-row>
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-card>
              <b-row>
                <b-col
                    cols="6"
                    md="3"
                >
                  <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="商品名称:"
                      label-for="name"
                      label-size="sm"
                  >
                    <b-form-input
                        id="name"
                        v-model="productName"
                        class="d-inline-block mr-1"
                        size="sm"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6"
                    md="3"
                >
                  <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="69码:"
                      label-for="code"
                      label-size="sm"
                  >
                    <b-form-input
                        id="code"
                        v-model="code"
                        class="d-inline-block mr-1"
                        size="sm"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6"
                    md="3"
                >
                  <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="单据编号:"
                      label-for="receipts_no"
                      label-size="sm"
                  >
                    <b-form-input
                        id="receipts_no"
                        v-model="orderNo"
                        class="d-inline-block mr-1"
                        size="sm"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6"
                    md="3"
                >
                  <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="单据类型:"
                      label-for="order_type"
                      label-size="sm"
                  >
                    <v-select
                        id="order_type"
                        :options="order_type_select"
                        :clearable="true"
                        v-model = "orderType"
                        class="select-size-sm"
                        placeholder="选择单据类型"
                        :reduce="option => option.value"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6"
                    md="3"
                >
                  <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="客户/渠道:"
                      label-for="storeName"
                      label-size="sm"
                  >
                    <b-form-input
                        id="storeName"
                        v-model="storeName"
                        class="d-inline-block mr-1"
                        size="sm"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" cols="6">
                  <xy-input-button label="销售/退货日期"
                                   type="time"
                                   v-on:change="fromChildren($event,'sale_return_date_str')"
                                   :params="['sale_return_date_str']"
                                   :value="saleReturnDateStr"
                  >
                  </xy-input-button>
                </b-col>


                <b-col
                    cols="12"
                    md="12"
                    class=" demo-inline-spacing d-flex align-items-center justify-content-end"
                >
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      type="submit"
                  >
                    <span class="align-right">查询</span>
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      type="reset"
                  >
                    <span class="align-right">重置</span>
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
                      @click="clearCheckBox()"
                      class="mr-1"
                      size="sm"
                  >
                    <feather-icon
                        icon="Trash2Icon"
                        class="mr-50"
                    />
                    <span class="align-middle">清空勾选</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-form>
      </div>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >
        <!-- 明细 -->
        <template #row-details="row">
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.items" :fields="fields" v-model="tableDetail['indexTable'+row.item.ext.items[0].order_id]">
                <template v-slot:cell(#)="row">
                  <b-form-checkbox
                      v-if="row.item.sales_type.indexOf('线下')>-1"
                      disabled
                      @change="checkboxClickDetail(row.item,$event)"
                      v-model="isCheckedObjDetail['indexDetail'+row.item.item_id]">
                  </b-form-checkbox>
                  <b-form-checkbox
                      v-if="row.item.sales_type.indexOf('线下')<0"
                      @change="checkboxClickDetail(row.item,$event)"
                      v-model="isCheckedObjDetail['indexDetail'+row.item.item_id]">
                  </b-form-checkbox>
                </template>
                <template #cell(code)="data">
                  {{ data.item.code}}
                </template>
                <template #cell(name)="data">
                  {{ data.item.name}}
                </template>
                <template #cell(productUnit)="data">
                  {{ getCodeLabel('product_unit', data.item.product_unit)}}
                </template>
                <template #cell(qty)="data">
                  {{ data.item.qty}}
                </template>
                <template #cell(invoiceQty)="data">
                  {{ data.item.invoice_qty}}
                </template>
                <template #cell(invoiceLeftQty)="data">
                  {{ data.item.invoice_left_qty}}
                </template>
                <template #cell(product_cost_tax)="data">
                  {{ data.item.product_cost_tax}}
                </template>
                <template #cell(contain_tax_amount)="data">
                  {{ data.item.contain_tax_amount}}
                </template>

              </b-table>
            </div>
          </b-card>
        </template>

        <template v-slot:head(#)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>

        <template v-slot:cell(#)="row">
          <b-form-checkbox @change="checkboxClick(row.item,$event)" v-model="isCheckedObj['index'+row.item.id]">
          </b-form-checkbox>
        </template>

        <!-- Columns -->
        <template #cell(orderNo)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="toggleDetail(data)"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          #{{data.item.order_no}}
        </template>

        <!-- Columns -->
        <template #cell(sale_return_date)="data">
          {{data.item.sale_return_date}}
        </template>

        <!-- Columns -->
        <template #cell(channelName)="data">
          {{data.item.channel_name}}
        </template>

        <!-- Columns -->
        <template #cell(orderType)="data">
          {{data.item.order_type}}
        </template>

        <!-- Columns -->
        <template #cell(qty)="data">
          {{data.item.qty}}
        </template>

        <!-- Columns -->
        <template #cell(containTaxAmount)="data">
          {{data.item.contain_tax_amount}}
        </template>

        <!-- Columns -->
<!--        <template #cell(invoiceRemainAmount)="data">
          {{data.item.invoice_remain_amount}}
        </template>-->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from "vue-ripple-directive";
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions } from '@core/utils/filter'
import invoiceApplyEditStore from "@/views/apps/invoiceapply/invoiceApplyEditStore";
import invoiceApplyItemSelectList from './invoiceApplyItemSelectList'
import XyInputButton from "@/views/components/xy/XyInputButton";

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    XyInputButton,
    vSelect,
  },
  data() {
    return {
      fields: [
        {
          key: '#',
          label: '#',
        },
        {
          key: 'code',
          label: '69码',
        },
        {
          key: 'name',
          label: '名称',
        },
        {
          key: 'productUnit',
          label: '单位',
        },
        {
          key: 'qty',
          label: '数量',
        },
        {
          key: 'invoiceQty',
          label: '开票数量',
        },
        {
          key: 'invoiceLeftQty',
          label: '开票剩余数量',
        },
        {
          key: 'product_cost_tax',
          label: '含税单价',
        },
        {
          key: 'contain_tax_amount',
          label: '含税金额',
        },
      ],
      indeterminate: false,
      allchecked: false,
      // checked: [],
      isCheckedObj: {},
      tableDetail:{},
      isCheckedObjDetail: {},

      checkedDetail: [],
      order_type_select: [
        {label: '销售', value: '1'},
        {label: '退货', value: '2'}
      ]
    }
  },
  watch: {
    start:'refetchData',
    limit:'refetchData'
  },
  props:{
    store_id: {
      type: Number
    },
    sales_type: {
      type: String,
      default: 0
    },
    other_company_id: {
      type: String
    },
    is_traveler: {
      type: String,
      default: ""
    },
    selected_str: {
      type: String,
      default: ""
    },
  },

  methods: {
    toggleDetail(row) {
      row.toggleDetails();
      setTimeout(() =>{
        let checked = this.isCheckedObj['index' + row.item.id];
        if(checked !== undefined && checked){
          let checkArr = [];
          for(let i=0;i<this.checkedDetail.length;i++){
            checkArr.push(this.checkedDetail[i].item_id)
          }
          this.tableDetail['indexTable'+row.item.id].forEach(ele => {
            let index = checkArr.indexOf(ele.item_id);
            if (index > -1 && !checked) {
              this.checkedDetail.splice(index, 1);
              checkArr.splice(index, 1);
            } else if(index < 0 && checked){
              this.checkedDetail.push(ele);
              checkArr.push(ele.item_id);
            }
            this.isCheckedObjDetail['indexDetail' + ele.item_id] = true
          });
          // }
        }},1000)
    },
    getSelected: function () {
      return this.checkedDetail
    },
    searchByCondition(){
      this.refetchData()
      // this.clearCheckBox()
    },
    resetCondition() {
      this.productName = ''
      this.code = ''
      this.storeName = ''
      this.orderNo = ''
      this.orderType = ''
      this.saleReturnDateStr = ''
      this.refetchData()
    },
    // 清空选择框
    clearCheckBox() {
      this.checkedDetail.forEach(element => {
        console.log(element)
        this.isCheckedObj['index' + element.order_id] = false;
        this.isCheckedObjDetail['indexDetail' + element.item_id] = false;
      });
      this.allchecked = false;
      this.indeterminate = false
      this.checkedDetail = []
      this.$forceUpdate()
    },
    refetchData(){
      this.$refs.refListTable.refresh()
      //   this.clearCheckBox()
    },
    // 点击全选按钮
    toggleAll(checked) {
      this.table.forEach(element => {
        this.isCheckedObj['index' + element.id] = checked;
        let checkArr = [];
        for(let i=0;i<this.checkedDetail.length;i++){
          checkArr.push(this.checkedDetail[i].item_id)
        }
        if(this.tableDetail['indexTable'+element.id]){
            this.tableDetail['indexTable'+element.id].forEach(ele => {
              let index = checkArr.indexOf(ele.item_id);
              if (index > -1 && !checked) {
                this.checkedDetail.splice(index, 1);
                checkArr.splice(index, 1);
              } else if(index < 0 && checked){
                this.checkedDetail.push(ele);
                checkArr.push(ele.item_id);
              }
              this.isCheckedObjDetail['indexDetail' + ele.item_id] = checked
            });
        }else{
          element.ext.items.forEach(ele =>{
            let index = checkArr.indexOf(ele.item_id);
            if (index > -1 && !checked) {
              this.checkedDetail.splice(index, 1);
              checkArr.splice(index, 1);
            } else if(index < 0 && checked){
              this.checkedDetail.push(ele);
              checkArr.push(ele.item_id);
            }
          })
        }
      });
      this.$forceUpdate()
    },
    // 复选框点击选中与取消
    checkboxClick(item,checked) {
      //全选框
      let allselect = true;
      this.table.forEach(element => {
        if (this.isCheckedObj['index' + element.id] !== true) {
          allselect = false;
        }
      });
      this.allchecked = allselect;
      //明细单选框
      let checkArr = [];
      for(let i=0;i<this.checkedDetail.length;i++){
        checkArr.push(this.checkedDetail[i].item_id)
      }
      if(this.tableDetail['indexTable'+item.id]){
          this.tableDetail['indexTable'+item.id].forEach(element => {
            let index = checkArr.indexOf(element.item_id);
            if (index > -1 && !checked) {
              this.checkedDetail.splice(index, 1);
              checkArr.splice(index, 1);
            } else if(index < 0 && checked){
              this.checkedDetail.push(element);
              checkArr.push(element.item_id);
            }
            this.isCheckedObjDetail['indexDetail' + element.item_id] = checked
          });
      }else{
        item.ext.items.forEach(element =>{
          let index = checkArr.indexOf(element.item_id);
          if (index > -1 && !checked) {
            this.checkedDetail.splice(index, 1);
            checkArr.splice(index, 1);
          } else if(index < 0 && checked){
            this.checkedDetail.push(element);
            checkArr.push(element.item_id);
          }
        })
      }

      this.$forceUpdate()
    },
    // 复选框点击选中与取消
    checkboxClickDetail(item,checked) {
      let checkArr = [];
      for(let i=0;i<this.checkedDetail.length;i++){
        checkArr.push(this.checkedDetail[i].item_id)
      }
      let index = checkArr.indexOf(item.item_id);
      if (index > -1 && !checked) {
        this.checkedDetail.splice(index, 1);
        checkArr.splice(index, 1);
      } else if(index < 0 && checked){
        this.checkedDetail.push(item);
        checkArr.push(item.item_id);
      }
      //父级勾选框
      let select = true;
      this.tableDetail['indexTable'+item.order_id].forEach(element => {
        if (this.isCheckedObjDetail['indexDetail' + element.item_id] !== true) {
          select = false;
        }
      });
      this.isCheckedObj['index' + item.order_id] = select;

      //全选框
      let allselect = true;
      this.table.forEach(element => {
        if (this.isCheckedObj['index' + element.id] !== true) {
          allselect = false;
        }
      });
      this.allchecked = allselect;

      this.$forceUpdate()
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {
    // Register module
    if (!store.hasModule('invoiceApplyItemSelectList')) store.registerModule('invoiceApplyItemSelectList', invoiceApplyEditStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('invoiceApplyItemSelectList')) store.unregisterModule('invoiceApplyItemSelectList')
    })
    const fromChildren = function (params, modal) {
      this.saleReturnDateStr =params==null?null:params[modal]
      this.$forceUpdate()
    }

    const {
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      // refetchData,
      productName,
      code,
      storeName,
      orderNo,
      orderType,
      saleReturnDateStr,
    } = invoiceApplyItemSelectList(
        {store_id:props.store_id,sales_type:props.sales_type
          ,other_company_id:props.other_company_id,is_traveler:props.is_traveler,selected_str:props.selected_str}
    )

    return {
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      // refetchData,

      fromChildren,
      productName,
      code,
      storeName,
      orderNo,
      orderType,
      saleReturnDateStr,
      // Filter
      avatarText,
      getCodeLabel,
      getCode,
      getCodeColor,
      getCodeOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
